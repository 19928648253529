import { Loader2 } from "lucide-react";
import { useFormStatus } from "react-dom";
import {
  Button as CNButton,
  type ButtonProps,
} from "@/components/third-party/shadcn/button";
import { forwardRef } from "react";

type LoadingButtonProps = ButtonProps & {
  loading?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, LoadingButtonProps>(
  function LoadingButton({ loading, children, ...props }, ref) {
    const { pending } = useFormStatus();

    const isLoading = loading ?? pending;

    return (
      <CNButton ref={ref} disabled={isLoading} {...props}>
        <>
          {isLoading ? "Please wait" : children}
          {isLoading && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
        </>
      </CNButton>
    );
  }
);
