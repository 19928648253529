import axios, { AxiosInstance } from "axios";
import Config from "@/config";
import store, { logout, RootState } from "@/store/store";

export class BaseService {
  protected client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: Config.apiUrl,
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
    });
    
    this.setupRequestInterceptors();
    this.setupResponseInterceptors();
  }

  protected getAccessToken() {
    const state: RootState = store.getState();
    return state.auth.accessToken;
  }

  protected setupRequestInterceptors() {
    this.client.interceptors.request.use(async (request: any) => {
      const token = this.getAccessToken();
      if (request.headers && token) {
        request.headers.Authorization = `Bearer ${token}`;
      }
      return request;
    });
  }

  private setupResponseInterceptors() {
    this.client.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401) {
          if (!originalRequest._retry) {
            originalRequest._retry = true;
            store.dispatch(logout());
          }
        }
        return Promise.reject(error);
      }
    );
  }

}