import BaseConfig from "./config.base"
import ProdConfig from "./config.prod"
import DevConfig from "./config.dev"

let ExtraConfig = ProdConfig
const isDev = process.env.NODE_ENV !== "production"

if (isDev) {
  ExtraConfig = DevConfig
}

const Config = { ...BaseConfig, ...ExtraConfig}

export default Config