import React from "react";
import {
  DropEvent,
  DropzoneOptions,
  FileRejection,
  useDropzone,
} from "react-dropzone";
import { Button } from "@/components/base";
import { Icons, TrashIcon } from "@/components/icons";

export interface Dropzone {
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  accept: any;
  maxFiles?: number;
  filenames?: string[];
  existingFiles?: { id: number | string; name: string }[];
  onDelete?: (filename: string, isExisting: boolean, id?: string) => void;
  isInvalid?: boolean;
  invalidInputMessage?: string;
  additionalText?: string;
  onlyButton?: boolean;
  type: "picture" | "document" | "video";
  title?: string;
  isUploading?: boolean;
  uploadProgress?: number;
}

export const FileUploadInput: React.FC<Dropzone> = (payload: Dropzone) => {
  const {
    onDrop,
    accept,
    maxFiles,
    filenames,
    existingFiles,
    onDelete,
    additionalText,
    isInvalid,
    invalidInputMessage,
    onlyButton,
    type,
    title,
    isUploading,
    uploadProgress,
  } = payload;

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept,
    maxFiles: maxFiles ? maxFiles : 5,
    multiple: maxFiles ? maxFiles > 1 : false,
  } as DropzoneOptions);

  const hasExistingFiles = existingFiles && existingFiles.length > 0;
  const hasNewlyUploadedFiles = filenames && filenames.length > 0;

  return (
    <div>
      <>
        {title && (
          <div className="text-[14px] font-semibold mb-[2px]">{title}</div>
        )}
      </>
      <div
        {...getRootProps()}
        className={`flex flex-col justify-between border-2 border-dotted p-6 rounded-md w-full min-h-[150px] ${isDragActive ? "border-blue-500" : "border-gray-300"}`}
      >
        <div>
          <input {...getInputProps()} />
          {!filenames?.length && (
            <div className="w-full text-[14px] text-center">
              {isDragActive ? (
                <p>Drop the files here</p>
              ) : (
                <p>Drag 'n' drop some files here, or click to select files</p>
              )}
            </div>
          )}

          <div className="flex flex-col gap-[6px] py-2">
            {filenames && filenames.length > 0 && (
              <>
                {filenames.map((filename: string, index: number) => (
                  <div key={index} className="flex flex-row justify-between">
                    <span className="truncate text-[16px]">{filename}</span>
                    <button
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onDelete && onDelete(filename, false);
                      }}
                    >
                      <Icons.Trash />
                    </button>
                  </div>
                ))}
              </>
            )}
          </div>

          {hasExistingFiles && (
            <div className="flex flex-col gap-[6px] py-2">
              {existingFiles && existingFiles.length > 0 && (
                <>
                  {existingFiles.map((file: any, index: number) => (
                    <div key={index} className="flex flex-row justify-between">
                      <span className="truncate text-[16px]">{file.name}</span>
                      <button
                        onClick={(e: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onDelete && onDelete(file.name, true, file.id);
                        }}
                      >
                        <Icons.Trash />
                      </button>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>

        <UploadButtons
          type={type}
          isUploaded={hasExistingFiles || hasNewlyUploadedFiles}
          isInvalid={isInvalid}
          open={open}
          invalidInputMessage={invalidInputMessage}
          isUploading={isUploading}
        />
      </div>
    </div>
  );
};

const UploadButtons: React.FC<any> = (props: any) => {
  const {
    isUploaded,
    isInvalid,
    open,
    invalidInputMessage,
    type,
    isUploading,
  } = props;

  return (
    <>
      <Button
        disabled={isUploading}
        onClick={open}
        variant="outline"
        loading={isUploading}
      >
        {isUploaded ? "Select More" : `Select ${type}`}
      </Button>

      {isInvalid === true ? (
        <div className="text-red-700 font-bold mt-5">
          {invalidInputMessage ?? "You must upload a file."}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
