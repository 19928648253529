import { Input as ShadInput } from "../third-party/shadcn/input";
import { Label } from "../third-party/shadcn/label";

interface InputProps {
  id?: string;
  label?: string;
  type?: string;
  [key: string]: any; // To allow additional props
}

export function Input({ id, label, type, ...props }: InputProps) {
  return (
    <div className="flex flex-col gap-2 w-full">
      <Label htmlFor={id} className="font-semibold">{label}</Label>
      <ShadInput id={id} type={type} {...props} />
    </div>
  );
}