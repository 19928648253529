import { Checkbox as CnCheckbox } from "../third-party/shadcn/checkbox";

// Update function to accept props
export function Checkbox({
  title,
  onChange,
}: {
  title: string;
  onChange: (event: any) => void;
}) {
  return (
    <div className="flex items-center gap-2">
      <CnCheckbox id="terms" onCheckedChange={onChange} />
      <label
        htmlFor="terms"
        className="text-md leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {title}
      </label>
    </div>
  );
}
