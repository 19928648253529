import axios, { AxiosRequestConfig } from "axios";
import { BaseService } from "../base.service";
import { MediaType } from "../../models/enums/media-type.enum";
import Config from "@/config";

class AssetService extends BaseService {
  async generateUploadUrls(
    fileName: string,
    mimeType: string,
    mediaType: MediaType
  ) {
    const resp = await this.client.post("/content/asset/upload", {
      fileName,
      mimeType,
      mediaType,
    });

    return resp?.data;
  }

  async getList(
    filter?: {
      originalName?: string;
      context?: any;
      mediaIds?: number[];
      includePresignedUrl?: boolean;
      uploaded?: boolean;
      searchTerm?: string;
      moduleId?: number;
      pathId?: number;
    },
    paging?: {
      page: number;
      limit: number;
    },
    sort?: {
      column: string;
      direction: "asc" | "desc";
    }
  ) {
    const resp = await this.client.post("/content/asset/list", {
      filter,
      paging,
      sort,
    });
    return resp?.data;
  }

  async get(s3MediaId: number) {
    const resp = await this.client.get(`/content/asset?s3MediaId=${s3MediaId}`);
    return resp?.data;
  }

  async uploadVideo(
    url: string,
    file: File,
    onProgress?: (progress: number) => void,
    onError?: (error: any) => void,
    onFinally?: (data: any) => void
  ) {
    const config: AxiosRequestConfig = {
      ...(onProgress && {
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          onProgress(percentCompleted);
        },
      }),
      headers: {
        "Content-Type": file.type,
      },
    };

    const uploadClient = axios.create({
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
    });

    try {
      const resp = await uploadClient.put(url, file, config);
      onFinally && onFinally(resp?.data);
      return resp?.data;
    } catch (error) {
      onError && onError(error);
      throw error;
    }
  }

  async markUploadComplete(
    s3MediaId: string,
    sequence: { pathId: number; moduleId: number }
  ) {
    const resp = await this.client.post("/content/asset/upload/complete", {
      s3MediaId,
      sequence,
    });

    return resp.data;
  }

  async createAllResources(s3MediaId: number, streamId?: number) {
    const resp = await this.client.post(`/content/asset/resources/create`, {
      s3MediaId,
      streamId,
    });

    return resp?.data;
  }

  async testCreateAllResources(s3MediaId: number, prompt: string) {
    const resp = await this.client.post(`/content/asset/resources/test/qoos`, {
      s3MediaId,
      prompt,
    });

    return resp?.data;
  }

  async updateSequence(
    s3MediaId: number,
    sequence: { pathId: number; moduleId: number }
  ) {
    const resp = await this.client.put(`/content/asset/sequence`, {
      s3MediaId,
      sequence,
    });
    return resp?.data;
  }

  async delete(assetid: number) {
    const resp = await this.client.delete(`/content/asset/${assetid}`);
    return resp?.data;
  }

  async deleteAllResources(assetid: number, includeTranscript: boolean) {
    let url = `/content/asset/${assetid}/resources`;
    if (includeTranscript) {
      url += `?includeTranscript=${includeTranscript}`;
    }

    const resp = await this.client.delete(url);
    return resp?.data;
  }
}

export const assetService = new AssetService();
