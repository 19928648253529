import { Textarea as ShadTextarea } from "../third-party/shadcn/textarea";
import { Label } from "../third-party/shadcn/label";

interface InputProps {
  id?: string;
  label?: string;
  type?: string;
  rows?: number;
  [key: string]: any; // To allow additional props
}

export function Textarea({ id, label, rows, ...props }: InputProps) {
  return (
    <div className="flex flex-col gap-2 w-full">
      <Label htmlFor={id} className="font-semibold">{label}</Label>
      <ShadTextarea id={id} rows={rows} {...props} />
    </div>
  );
}