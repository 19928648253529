let instance = "local";
let magiclinkKey = "pk_live_F1DB350ADA36DABA";
let apiUrl = "http://localhost:3100";

const config = {
  apiUrl,
  instance,
  magiclinkKey,
};

export default config;
