let instance = "prod";
let magiclinkKey = "pk_live_0EFCE08EA73BA525";
let apiUrl = "https://api-lms.qoos.ai";

const config = {
  apiUrl,
  instance,
  magiclinkKey,
};

export default config;
